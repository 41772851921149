import { Decimal, Klass } from "#school/lib/transforms"
import Big from "big.js"

export default class HomeworkPaper {
  id: number
  unrevised_count: number
  overdue: string
  submission_count: number
  spent_time: number
  @Decimal() score: Big
  accuracy: number
  state: "pending" | "started" | "rejected" | "submitted" | "processed" | "checked" | "confirmed"

  @Klass(Date) submitted_at?: Date
  @Klass(Date) confirmed_at?: Date
  @Klass(Date) created_at: Date
  @Klass(Date) updated_at: Date

  isFinished() {
    return [ "submitted", "processed", "checked", "confirmed" ].includes(this.state)
  }
}
