<script setup lang="ts">
import { computed, type HTMLAttributes, withDefaults } from 'vue'
import { cn } from '../utils'
import { CardPresenters } from '$presets'
import { provideCardPresenter, relayCardPreseterConfig, type CardPresenter, type CardPresenterConfig } from './types'

interface Props {
  class?: HTMLAttributes['class']
  preset?: keyof typeof CardPresenters | CardPresenter
}

const props = withDefaults(defineProps<Props & Partial<CardPresenterConfig>>(), {
  preset: 'standard',
})

const preseterConfig = relayCardPreseterConfig(props)
const presenter = provideCardPresenter(computed(() => {
  if (typeof props.preset != 'string') {
    return props.preset
  }

  return CardPresenters[props.preset]
}))
</script>

<template>
  <div :class="cn(presenter.root(preseterConfig), props.class)" style="--color-tint: var(--primary)">
    <slot></slot>
  </div>
</template>
