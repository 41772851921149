import { Decimal, Klass } from "#school/lib/transforms"
import Big from "big.js"

export class Element {
  extra?: any
  style: string[]
  node: string
  type: "block" | "inline"

  @Klass(Element) children?: Element[]
  content?: string
}
export class TestPaperEntry {
  id: number
  type: "heading" | "text" | "suite" | "question"
  @Klass(Element) formatted_content: Element[]
  partition_id?: number
  question_id?: number
  question_no?: string
  suite_id?: number
  @Decimal() question_score: Big
}

class Partition {
  id: number
}


export class QuestionOption {
  id: number
  value: string
  @Klass(Element) formatted_content: Element[]
}

export class QuestionInput {
  id: number
}

export class Question {
  id: number
  type: "select" | "input" | "char" | "boolean" | "text" | "dialog" | "speech" | "repeat" | "shengtong_open" | "photo"
  @Klass(Element) formatted_content: Element[]
  @Klass(QuestionOption) options: QuestionOption[]
  @Klass(QuestionInput) inputs: QuestionInput[]
}

export class Suite {
  id: number
  @Klass(Element) formatted_content: Element[]
}

export default class TestPaper {
  id: number
  @Klass(TestPaperEntry) entries: TestPaperEntry[]
  @Klass(Partition) partitions: Partition[]
  @Klass(Question) questions: Question[]
  @Klass(Suite) suites: Suite[]
}
