import type { BaseRequest } from '#school/requests'
import { defineStore, getActivePinia } from 'pinia'
import { ref, type App } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { KlassCache } from './KlassCache'
import { RequestList } from './RequestList'

export const usePageStore = defineStore('page', () => {
  const app = (getActivePinia() as any)._a as App
  const id = ref(1)
  const router = useRouter()
  const route = useRoute()
  const errors = ref([] as Error[])

  function clear() {
    request_list.reset()
  }

  function reload() {
    clear()
    id.value += 1
    router.push(route.fullPath)
  }

  function clearError() {
    errors.value = []
  }

  const request_list = new RequestList({ $axios: app.config.globalProperties.$axios })

  function requestList() {
    return request_list
  }

  return { clear, id, reload, errors, clearError, requestList }
})
