import { Klass } from '#school/lib/transforms'
import Clazz from './Clazz'
import School from './School'
import Student from './Student'

export default class Order {
  id: number
  no: string
  product_name: string
  quantity: number
  status: string
  status_text: string
  subtitle?: string

  @Klass(Date) deliver_at?: Date

  @Klass(Student) student: Student
  @Klass(Clazz) clazz: Clazz
  @Klass(School) school: School
}
