import { provide, inject, type InjectionKey } from 'vue'
import { type Ref } from "vue"
import { createProvideInject, createRelayPreseterConfig } from '../utils'

interface DialogContext {
  close: () => void
  onOpen: (callback: () => void) => void
}

export const {
  useInject: useDialogContext,
  useProvide: provideDialogContext
} = createProvideInject<DialogContext>('dialog-context')

export interface DialogPresenterConfig {
  variant?: 'standard'
}

export type DialogPresenter = {
  root: (config: Partial<DialogPresenterConfig>) => string
  close: (config: Partial<DialogPresenterConfig>) => string
  content: (config: Partial<DialogPresenterConfig>) => string
  description: (config: Partial<DialogPresenterConfig>) => string
  footer: (config: Partial<DialogPresenterConfig>) => string
  header: (config: Partial<DialogPresenterConfig>) => string
  scrollContent: (config: Partial<DialogPresenterConfig>) => string
  title: (config: Partial<DialogPresenterConfig>) => string
  trigger: (config: Partial<DialogPresenterConfig>) => string
}

export const {
  relayInjectPreseterConfig: relayDialogPreseterConfig
} = createRelayPreseterConfig<DialogPresenterConfig>('dialog-presenter-config', {
  variant: 'standard',
})

export const {
  useInject: useDialogPresenter,
  useProvide: provideDialogPresenter
} = createProvideInject<Ref<DialogPresenter>>('dialog-presenter')
