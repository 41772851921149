import { Klass } from '#school/lib/transforms'
import Student from "./Student"

export default class Studentship {
  id: number
  leaved: boolean
  @Klass(Date)
  joined_at: Date
  student_id: number
  student_actived_at: string
  student_authcode: string
  student_name: string
  student_usercode: string
  student_last_password_changed_at: string

  @Klass(Student) student: Student
}
