<template>
  <input type="radio" v-model="model_value" :value="value" :class="cn(presenter.radio(preseterConfig), props.class)">
</template>

<script setup lang="ts">
import { InputPresenters } from '$presets'
import { provideInputPresenter, relayInputPreseterConfig, type InputPresenter, type InputPresenterConfig } from './types'
import { type HTMLAttributes, computed, withDefaults } from 'vue'
import { cn } from '../utils'

interface Props {
  class?: HTMLAttributes['class']
  value: any
  preset: keyof typeof InputPresenters | InputPresenter
}

const model_value = defineModel<any[]>({ required: true })
const props = withDefaults(defineProps<Props & Partial<InputPresenterConfig>>(), {
  preset: "standard"
})

const preseterConfig = relayInputPreseterConfig(props)
const presenter = provideInputPresenter(computed(() => {
  if (typeof props.preset != 'string') {
    return props.preset
  }

  return InputPresenters[props.preset]
}))
</script>
