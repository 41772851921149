import { Klass } from '#school/lib/transforms'

export default class Student {
  id: number

  @Klass(Date)
  actived_at: Date

  authcode: string
  name: string
  usercode: string
  last_password_changed_at: string
}
