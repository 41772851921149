import { Account } from '#school/models'
import * as requests from '#school/requests'
import ErrorUnauthorized from '$commons/lib/ErrorUnauthorized'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { KlassCache } from './KlassCache'
import type { BaseRequest } from '$commons/lib/BaseRequest'

export const useSessionStore = defineStore('session', () => {
  const cache = new KlassCache<BaseRequest<any>>()
  const account = ref(undefined as Account | null | undefined)
  const error = ref(null)

  async function prepare(ctx: any) {
    if (account.value !== undefined) {
      return
    }

    try {
      account.value = await new requests.account.Get().setup(ctx).perform()
    } catch (e) {
      if (e instanceof ErrorUnauthorized) {
        account.value = null
        return
      }
      throw e
    }
  }

  function allow() {
    return function(action: string, resource: any) {
      if (account.value == null || account.value == undefined) {
        return false
      }

      return account.value.allow(action, resource)
    }
  }

  function clear() {
    account.value = null
  }

  return { cache, account, error, prepare, allow, clear }
})
