
import { type Ref } from "vue"
import { createProvideInject, createRelayPreseterConfig } from '../utils'

export interface CardPresenterConfig {
  variant?: 'standard' | 'table'
}

export type CardPresenter = {
  root: (config: Partial<CardPresenterConfig>) => string
  content: (config: Partial<CardPresenterConfig>) => string
  description: (config: Partial<CardPresenterConfig>) => string
  footer: (config: Partial<CardPresenterConfig>) => string
  header: (config: Partial<CardPresenterConfig>) => string
  title: (config: Partial<CardPresenterConfig>) => string
}

export const {
  relayInjectPreseterConfig: relayCardPreseterConfig
} = createRelayPreseterConfig<CardPresenterConfig>('card-presenter-config', {
  variant: 'standard',
})

export const {
  useInject: useCardPresenter,
  useProvide: provideCardPresenter
} = createProvideInject<Ref<CardPresenter>>('card-presenter')
