import { Klass } from '#school/lib/transforms'
import School from './School'

export default class SchoolIncomeStatistic {
  id!: number
  @Klass(Date) paid_date!: Date

  personal_paid_agent_cash!: number
  personal_refunded_agent_cash!: number

  agent_paid_amount!: number
  agent_paid_point!: number
  agent_refunded_amount!: number
  agent_refunded_point!: number

  @Klass(School) school!: School
}
