<template>
  <input type="checkbox" :value="value" v-model="checked" :indeterminate="indeterminate" :class="cn(presenter.checkbox(preseterConfig), props.class)">
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { type HTMLAttributes, withDefaults } from 'vue'
import { cn } from '../utils'
import { InputPresenters } from '$presets'
import { provideInputPresenter, relayInputPreseterConfig, type InputPresenter, type InputPresenterConfig } from './types'

interface Props {
  class?: HTMLAttributes['class']
  value: any
  preset: keyof typeof InputPresenters | InputPresenter
  indeterminate?: boolean
}

const modelValue = defineModel<any[]>({ required: true })
const props = withDefaults(defineProps<Props & Partial<InputPresenterConfig>>(), {
  preset: "standard",
  indeterminate: false
})

const preseterConfig = relayInputPreseterConfig(props)
const presenter = provideInputPresenter(computed(() => {
  if (typeof props.preset != 'string') {
    return props.preset
  }

  return InputPresenters[props.preset]
}))

const checked = computed(
  {
    get: () => modelValue.value?.includes(props.value),
    set: (value: boolean) => {
      if (value) {
        modelValue.value.push(props.value)
        modelValue.value = modelValue.value
      } else {
        modelValue.value = modelValue.value.filter((v) => v !== props.value)
      }
    }
  }
)
</script>
