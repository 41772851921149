import { Klass } from '#school/lib/transforms'
import Homework from "./Homework"
import Subject from "./Subject"
import Workathon from "./Workathon"

export class HomeworkStat {
  homework_id!: number
  checked_homework_papers_count: number
  submitted_homework_papers_count: number
  confirmed_homework_papers_count: number
  processed_homework_papers_count: number

  constructor(homework?: Homework) {
    if (homework) {
      this.homework_id = homework.id
    }
    this.checked_homework_papers_count = 0
    this.submitted_homework_papers_count = 0
    this.confirmed_homework_papers_count = 0
    this.processed_homework_papers_count = 0
  }
}

export default class WorkathonInfo extends Workathon {
  @Klass(Subject)
  subject!: Subject

  @Klass(Homework)
  homeworks!: Homework[]

  @Klass(HomeworkStat)
  homework_stats!: HomeworkStat[]
}
