import { Klass } from '#school/lib/transforms'
import EvaluationCoursewareRelation from "./EvaluationCoursewareRelation"

export default class JointEvaluation {
  id!: number
  title!: string
  subject_id!: number

  @Klass(Date) published_at!: Date
  @Klass(Date) deadline_at!: Date
  @Klass(EvaluationCoursewareRelation)
  evaluation_courseware_relations: EvaluationCoursewareRelation[]
}
