import { Decimal } from "#school/lib/transforms"
import Big from "big.js"

export default class AgentTransactionRecord {
  id: number
  bundle_id: number
  paid_channel: string
  paid_channel_text: string
  quantity: number
  state: string
  state_text: string
  @Decimal() total_price: Big
  @Decimal() total_final_price: Big
  student_ids: number[]

  results: Record<string, {
    state: "success" | "failure",
    content?: string
  }>
}
