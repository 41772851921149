import { bva } from '$ui'
import type { InputPresenter } from '$ui'

export const standard = {
  radio: bva(`
    border-primary
    focus:ring-transparent
    disabled:cursor-not-allowed disabled:opacity-50
  `, {}),
  checkbox: bva(`
    border-primary rounded-sm
    focus:ring-transparent
    disabled:cursor-not-allowed disabled:opacity-50
  `, {}),
} satisfies InputPresenter
