import type { BaseRequest, RequestContext } from "@/requests"
import { KlassCache } from "./KlassCache"
import { computed, ref, type Ref } from "vue"

class RequestWrapper<T extends BaseRequest<any>> {
  request: T
  ctx: RequestContext

  performed = false
  result = ref(undefined as Awaited<ReturnType<T['perform']>>)

  constructor(request: T, ctx: RequestContext) {
    this.request = request
    this.ctx = ctx
  }

  setup(callback: Parameters<T['setup']>[1] = null) {
    this.request.setup(this.ctx, callback)
    return this
  }

  wait(): Ref<Awaited<ReturnType<T['perform']>>> {
    return this.result
  }

  async perform(...args: Parameters<T['perform']>) {
    if (this.performed) {
      return this.result.value
    }

    this.performed = true
    this.result.value = await this.request.perform(...args)
    return this.result.value
  }
}

export class RequestList {
  caches = new KlassCache<RequestWrapper<BaseRequest<any>>>()
  wrappers: RequestWrapper<BaseRequest<any>>[] = []
  ctx: RequestContext
  aborter = new AbortController()

  constructor(ctx: RequestContext) {
    this.ctx = ctx
  }

  raw<R extends BaseRequest<any>>(request: R) {
    const wrapper = new RequestWrapper(request, { ...this.ctx, aborter: this.aborter })
    this.wrappers.push(wrapper)
    return wrapper as RequestWrapper<R>
  }

  one<K extends new (...args: any[]) => BaseRequest<any>>(klass: K, ...args: ConstructorParameters<K>) {
    const request = new klass(...args)
    return this.raw(request) as RequestWrapper<InstanceType<K>>
  }

  reuse<K extends new (...args: any[]) => BaseRequest<any>>(klass: K, ...args: ConstructorParameters<K>) {
    const result = this.caches.build(klass, (...args) => {
      const request = new klass(...args)
      return this.raw(request)
    }, ...args)

    return result as RequestWrapper<InstanceType<K>>
  }

  async perform() {
    const results = await Promise.all(this.wrappers.map(it => it.perform()))
    this.wrappers = []
    return results
  }

  reset() {
    console.log('reset')
    this.aborter.abort()

    this.aborter = new AbortController()
    this.caches.clear()
    this.wrappers = []
  }
}
