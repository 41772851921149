import { Klass } from '#school/lib/transforms'
import Student from "./Student"
import Subscription from "./Subscription"

export default class StudentInfo extends Student {
  primary_school_name: string
  clazz_names: string[]

  @Klass(Subscription)
  subscriptions: Subscription[]
}
