import { Type } from "class-transformer"
import Big from "big.js"
import { Decimal } from "#school/lib/transforms"

export class AttachmentVersion {
  url: string
}

export class Attachment {
  id: number
  @Type() versions: Record<string, AttachmentVersion>

  url(...codes: string[]) {
    for (const code of codes) {
      const version = this.versions[code]
      if (version) {
        return version.url
      }
    }

    return this.versions['original'].url
  }
}
