import { Klass } from '#school/lib/transforms'
import District from "./District"

export default class School {
  id: number
  name: string
  code: string
  province_id: number
  city_id: number
  district_id: number
  region_name: string
  authcode: string

  @Klass(District) district!: District
}
