
import { type Ref } from "vue"
import { createProvideInject, createRelayPreseterConfig } from '../utils'

export interface InputPresenterConfig {
}

export type InputPresenter = {
  radio: (config: Partial<InputPresenterConfig>) => string
  checkbox: (config: Partial<InputPresenterConfig>) => string
}

export const {
  relayInjectPreseterConfig: relayInputPreseterConfig
} = createRelayPreseterConfig<InputPresenterConfig>('input-presenter-config', {
  size: 'default',
})

export const {
  useInject: useInputPresenter,
  useProvide: provideInputPresenter
} = createProvideInject<Ref<InputPresenter>>('input-presenter')
